//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Tags, Topics } from '@/services/api.service';

var initialTags = [];
var initialLanguages = [];

export default {
  name: 'CreateChannelModalComponent',
  props: ['edit'],
  data() {
    return {
      showModal: true,
      inProgress: false,
      channel: {
        channelId: null,
        channel_type: 'open',
        image: null,
        selected_file: null,
        description: null,
        name: null,
        tags: [],
        languages: [],
      },
      channelTagFilter: initialTags,
      channelLanguageFilter: initialLanguages,

      errors: {
        channelTypeMissing: false,
        nameMissing: false,
        languageMissing: false,
      },
    };
  },
  components: {},
  methods: {
    onImageChanged(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      let that = this;
      reader.onload = (e) => {
        that.channel.selected_file = e.target.result;
      };
      reader.readAsDataURL(file);
      this.channel.image = file;
    },
    filterTagFn(val, update) {
      if (this.channelTagFilter.length !== 0) {
        // already loaded
        return update();
      }

      Tags.list().then((res) => {
        if (
          res.data &&
          res.data.data &&
          res.data.data.getTags &&
          res.data.data.getTags.length
        ) {
          update(() => {
            this.channelTagFilter = res.data.data.getTags.filter(
              (i) => i.category === 'TEACHING'
            );
          });
        } else {
          update(() => {
            this.channelTagFilter = [];
          });
        }
      });
    },
    filterLanguageFn(val, update) {
      if (this.channelLanguageFilter.length !== 0) {
        // already loaded
        return update();
      }

      Tags.list().then((res) => {
        if (
          res.data &&
          res.data.data &&
          res.data.data.getTags &&
          res.data.data.getTags.length
        ) {
          update(() => {
            this.channelLanguageFilter = res.data.data.getTags.filter(
              (i) => i.category === 'LANGUAGE'
            );
          });
        } else {
          update(() => {
            this.channelLanguageFilter = [];
          });
        }
      });
    },

    validateForm() {
      this.errors = {
        channelTypeMissing: false,
        nameMissing: false,
        languageMissing: false,
      };
      let hasErrors = false;
      if (!this.channel.channel_type) {
        hasErrors = true;
        this.errors.channelTypeMissing = true;
      }
      if (!this.channel.name) {
        hasErrors = true;
        this.errors.nameMissing = true;
      }
      if (!this.channel.languages || !this.channel.languages.length) {
        hasErrors = true;
        this.errors.languageMissing = true;
      }

      return !hasErrors;
    },

    formSubmit() {
      if (this.validateForm()) {
        //can submit
        this.inProgress = true;

        let action = this.edit ? Topics.edit : Topics.create;
        action(this.channel).then(
          (res) => {
            if (res && res.topic) {
              this.$emit('closeModal', res.topic);
            }
            this.inProgress = false;
          },
          () => {
            this.inProgress = false;
            this.errors.cannotCreate = true;
            this.$q.notify({
              message: 'Cannot create/edit channel',
              color: 'warning',
            });
          }
        );
      } else {
        return false;
      }
    },
  },
  watch: {
    showModal() {
      this.$emit('closeModal', null);
    },
  },
  computed: {},
  mounted() {
    if (this.edit) {
      this.inProgress = true;
      Topics.get(this.edit).then(
        (res) => {
          if (res && res.data && res.data.data && res.data.data.getTopic) {
            const topic = res.data.data.getTopic;
            this.channel.channel_type = topic.isPrivate ? 'private' : 'open';
            this.channel.name = topic.title;
            this.channel.selected_file = topic.image;
            this.channel.description = topic.description;
            this.channel.languages = topic.tags.filter(
              (i) => i.category === 'LANGUAGE'
            );
            this.channel.tags = topic.tags.filter(
              (i) => i.category === 'TEACHING'
            );
            this.channel.channelId = topic.channelId;
          }
          this.inProgress = false;
        },
        () => {
          this.inProgress = false;
        }
      );
    }
  },
};
