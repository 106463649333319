//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaAvatar from '@/components/SanghaAvatar.vue';
import { Topics } from '@/services/api.service';
import { NUMBER_OF_RESULTS_PER_PAGE } from '@/services/config';

export default {
  name: 'UserPickerModalComponent',
  data() {
    return {
      showModal: true,

      currentPage: 0,
      hasNextPage: false,

      userList: [],
      userSearch: {
        searchString: null,
      },
      inProgress: null,
      currentOptions: {
        limit: NUMBER_OF_RESULTS_PER_PAGE,
        offset: 0,
        channelId: null,
      },

      buttonIntersectionOptions: {
        handler: this.onLoadMoreButtonIntersection,
      },
      intersectionStarted: false,
    };
  },
  props: ['channelId', 'selectable', 'title', 'excludeSelf'],
  components: {
    SanghaAvatar,
  },
  methods: {
    searchUser(options) {
      if (options) {
        this.currentOptions = { ...this.currentOptions, ...options };
      }

      if (this.excludeSelf) this.currentOptions.excludeSelf = this.excludeSelf;
      this.currentOptions.channelId = this.channelId;

      this.inProgress = true;
      Topics.listChannelMembers(this.currentOptions).then(
        (res) => {
          let data = res.data.data.getTopic;

          if (options && options.offset > 0) {
            this.userList = [...this.userList, ...(data || [])];
          } else {
            this.userList = data;
          }
          this.inProgress = false;
          this.hasNextPage = res.hasNextPage;
          setTimeout(() => {
            this.intersectionStarted = true;
          }, 500);
        },
        () => {
          this.inProgress = false;
        }
      );
    },
    loadNextPage() {
      this.currentPage = this.currentPage + 1;
      const options = {
        offset: NUMBER_OF_RESULTS_PER_PAGE * this.currentPage,
      };
      this.searchUser(options);
    },
    loadNextPageIntersection(obj) {
      if (!this.inProgress && obj.isIntersecting && obj.intersectionRatio > 0)
        this.loadNextPage();
    },
    searchUserByName() {
      const options = {
        limit: NUMBER_OF_RESULTS_PER_PAGE,
        offset: 0,
        searchString: this.userSearch.searchString,
      };
      this.searchUser(options);
    },
    onLoadMoreButtonIntersection(obj) {
      if (!this.intersectionStarted) {
        return;
      }
      if (!this.inProgress && obj.isIntersecting && obj.intersectionRatio > 0) {
        this.intersectionStarted = false;
        this.loadNextPage();
      }
    },
  },
  watch: {
    showModal() {
      this.$emit('closeModal', null);
    },
  },
  computed: {},
  mounted() {
    this.searchUser();
  },
};
